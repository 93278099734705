import axios from "axios";
// Create default axios with a header
const URLs = {
  prod: `https://apis.datamoire.com/${process.env.REACT_APP_STAGE !== 'prod' ? `${process.env.REACT_APP_STAGE}/` : ''}v1/portal`,
  // prod: 'https://website-api.datamoire.com',
  local: "http://localhost:3001",
};

const baseURL = URLs[process.env.REACT_APP_MOCK_ENV] || URLs.prod;
axios.defaults.baseURL = baseURL;


// const HTTP_STATUS_CODES = {
//   OK: 200,
//   BAD_REQUEST: 400,
//   UNAUTHORIZED: 403
// }

// const clearStorage = () => {
//   window.localStorage.removeItem("dmToken");
//   window.localStorage.removeItem("dmPortalUser");
//   window.localStorage.removeItem("isLoggedIn");
//   // window.location.href = "/login";
// };

const mockDBRequestService = () => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_GW_KEY,
      authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb3dJZCI6ImNsZjdwN3I3bzAwMG54ajExeXB5c2xncmwiLCJlbWFpbCI6ImJlcmthbnQuYWtndXZlcmNpbkBkYXRhbW9pcmUuY29tIiwidXNlclR5cGVJZCI6ImNsZHMxbGxpNDAwMGdyeGl1dHc2bGpqejAiLCJjb21wYW55SWQiOiJjbGRzMWppZzEwMDAzcnhpN2xiYWZubWVhIiwiY29tcGFueVR5cGVzIjpbeyJjb21wYW55SWQiOiJjbGRzMWppZzEwMDAzcnhpN2xiYWZubWVhIiwiY29tcGFueVR5cGVJZCI6ImNsZHMxZndjNTAwMGNyeGFzNTNtZG55MjkiLCJyb3dJZCI6ImNscDF4MmxybTAwMDRyeGVvM3gxdHNueGMiLCJjb21wYW55VHlwZSI6eyJpZCI6ImNsZHMxZndjNTAwMGRyeGFzMmsza29yajEiLCJyb3dJZCI6ImNsZHMxZndjNTAwMGNyeGFzNTNtZG55MjkiLCJjcmVhdGVkQXQiOiIyMDIzLTAyLTA1VDIzOjUxOjMwLjY3N1oiLCJ1cGRhdGVkQXQiOiIyMDIzLTAyLTA1VDIzOjUxOjMwLjY3N1oiLCJkZWxldGVkQXQiOm51bGwsImlzRGVsZXRlZCI6ZmFsc2UsIm5hbWUiOiJEQVRBTU9JUkUifX1dLCJwYXJlbnRDb21wYW55IjpudWxsLCJyZXNlbGxlckNvbXBhbnlJZCI6bnVsbCwiaWF0IjoxNzI3MzYzODI4LCJleHAiOjE3Mjc5Njg2Mjh9.C98fqcyZeHGqbm97g9JT8JuIEtXnCpfm2F0YNIrx3HM`,
    },
    // retry: 3,
    // retryDelay: 3000,
  });

  // instance.interceptors.request.use((value) =>{
  //   if (!window.navigator.onLine) {
  //       return Promise.reject(new Error('NETWORK_OFFLINE'));
  //     }
  //   return value
  // }, (err) =>{
  //   console.log('request error', err)
  //   return Promise.reject(err);
  // }
  // )

  // instance.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const { config } = error;

  //     if (error.response?.data?.errorCode === "ERR_101") {
  //       clearStorage();
  //       return Promise.reject(error);
  //     }

  //     if (
  //       error.response?.data?.success === false &&
  //       error.response?.data?.message === "TOKEN_EXPIRED"
  //     ) {
  //       clearStorage();
  //       return Promise.reject(error);
  //     }
      
  //     if(error?.response?.status === HTTP_STATUS_CODES.BAD_REQUEST) {
  //       return Promise.reject(error);
  //     }

  //     if(error?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
  //       clearStorage();
  //       return Promise.reject(error);
  //     }
      
  //     // in order to prevent check-user endpoint issue. We have to remove this if condition.

   
      
  //     if (error.response?.data?.success === false && error.response?.data?.message === 'Unauthorized') { 
  //       return Promise.reject(error);
  //     }
      
  //     if (error.message === 'NETWORK_OFFLINE') {
  //       return Promise.reject(error);
  //     }

  //     // if (!config || !config.retry) {
  //     //   clearStorage();
  //     //   return Promise.reject(error);
  //     // }
            
  //     const delayRetryRequest = new Promise((resolve) => {
  //       setTimeout(() => {
  //           config.retry = config.retry - 1;
  //           console.log(
  //             "Retrying to send the request to:",
  //             `${URLs.local}${config.url}`
  //           );
  //           resolve();
  //       }, config.retryDelay || 1000);
  //     });


  //     if (config && config.retry > 0) {
  //       return delayRetryRequest.then(() => instance(config));
  //     } else {
  //       if (!window.navigator.onLine) {
  //         return Promise.reject(new Error('NETWORK_OFFLINE'));
  //       }
  //     }
  //   }
  // );

  return instance;
};

export default mockDBRequestService;

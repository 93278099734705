import { allStudyDimensions } from "./helpers";

export const createAdPreviewLayout = (question, styling, dimension, themeMode, skipWelcomeScreen, inCustomizeMode) => {
    const currentStyling = styling[themeMode] || styling?.dark;

    const createCssVariables = (styling) => {
        const defaultValues = {
            '--background-color': '#121212',
            '--primary-gradient': 'conic-gradient(from 46deg at 13.43% 15.68%, #6344EE 0deg, #75C5FA 360deg)',
            '--card-background': '#6344EE',
            '--card-margin': '0%',
            '--text-color': '#fff',
            '--button-background': '#1f1f1f',
            '--button-hover': '#a560e0',
            '--progress-bar-active': '#fff',
            '--progress-bar-inactive': '#444',
            '--progress-bar-active-before': '#9e52fc',
            '--question-text-padding': '5px',
            '--question-text-size': '0.9rem',
            '--answer-text-size': '0.6rem',
            '--answers-gap': '1%',
            '--secondary-gradient': '#75C5FA', // Added secondary gradient default
        };

        const variableMap = {
            '--background-color': 'backgroundColor',
            '--primary-gradient': 'primaryGradientColor',
            '--card-background': 'cardBackground',
            '--card-margin': 'cardMargin',
            '--text-color': 'textColor',
            '--button-background': 'buttonBackground',
            '--button-hover': 'buttonHover',
            '--progress-bar-active': 'progressBarActive',
            '--progress-bar-inactive': 'progressBarInactive',
            '--progress-bar-active-before': 'progressBarActiveBefore',
            '--question-text-padding': 'questionTextPadding',
            '--question-text-size': 'questionTextSize',
            '--answer-text-size': 'answerTextSize',
            '--answers-gap': 'answersGap',
            '--secondary-gradient': 'secondaryGradientColor', // Added mapping for secondary gradient
        };

        return Object.entries(variableMap).map(([cssVar, key]) => {
            const currentKey = styling[key];
            if (cssVar === '--primary-gradient') {
                return `${cssVar}: conic-gradient(from 46deg at 13.43% 15.68%, ${currentKey.value} 0deg, ${styling.secondaryGradientColor.value} 360deg);`;
            }
            if (currentKey?.type === 'input') {
                return `${cssVar}: ${currentKey.value}${currentKey.valueType};`;
            } else {
                return `${cssVar}: ${currentKey?.value || defaultValues[cssVar]};`;
            }
        }).join('\n');
    };

    const cssVariables = createCssVariables(currentStyling);

    const questionText = question.text.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, ' ').trim();

    const answersHtml = question?.answers.map(answer => {
        return `<label for="${answer.rowId}" class="option-button" key="${answer.rowId}">
                    <input id="${answer.rowId}" name="answer" type="radio" data-dm-target="${answer.rowId}" data-dm-category="Answer" data-dm-label="answer_form_element_selected" data-dm-group="1" data-dm-type="1" style="font-size: 12px" required="" />
                    <span>${answer.text}</span>
                </label>`
    }).join('');

    // Find the dimension object from adDimensions
    const selectedDimension = allStudyDimensions.find(dim => dim.name === dimension);
    let studyWidth, studyHeight;

    if (selectedDimension && selectedDimension.dimensionType === "STANDARD") {
        // Split the dimension name to get width and height
        [studyWidth, studyHeight] = selectedDimension.name.split('x').map(Number);
    } else {
        [studyWidth, studyHeight] = [368, 769];
    }

    return (`
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0">
                <meta name="ad.size" content="width=300,height=250">
                <title>
                    Datamoire Study Form
                </title>
                <style>
                    :root {
                       ${cssVariables}
                    }

                     [data-theme="dark"] {
                        ${createCssVariables(styling.dark)}
                    }

                    [data-theme="light"] {
                        ${createCssVariables(styling.light)}
                    }
                </style>
                <style>
                    body {
                        font-family: Config Rounded, Arial, sans-serif;
                        background-color: var(--background-color);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100vh;
                        margin: 0;
                        /* overflow: hidden; */
                    }

                    .welcome-screen-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        /* padding: 5%; */
                        background: var(--primary-gradient);
                        color: var(--text-color);
                        text-align: center;
                    }

                    .welcome-card {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        min-height: 80%;
                        height: auto;
                        border-radius: 15px;
                        background-color: var(--card-background);
                        margin: var(--card-margin);
                        /* padding: 3%; */
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                    }

                    #welcome-animation-container {
                        height: auto; /* Adjust height as needed */
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 5vh;
                    }
                    #welcome-animation-container .svg-image {
                        height: 8vh;
                        min-height: 20px;
                    }

                    .welcome-note {
                        font-size: var(--question-text-size);
                        font-weight: bold;
                        margin-bottom: 10vh;
                        width: 95%;
                        color: var(--text-color);
                    }

                    .continue-button {
                        width: 60%;
                        min-width: 100px;
                        min-height: 15px;
                        padding: 1%;
                        text-align: center;
                        align-self: center;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--button-background);
                        color: var(--text-color);
                        border: none;
                        border-radius: 5px;
                        font-size: var(--answer-text-size);
                        cursor: pointer;
                    }
                    .continue-button:hover {
                        background-color: var(--button-hover);
                    }
            
                    .dm-study-container {
                        width: ${studyWidth}px;
                        height: ${studyHeight}px;
                        display: ${/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? '-webkit-flex' : 'flex'};
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        align-self: flex-start;
                        /* width: 100%; */
                        /* height: 100%; */
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                        background: var(--primary-gradient);
                        /* code to remove later  is below*/
                        /* transform: scale(0.8); */
                        /* width: 300px;
                        height: 250px;
                        padding: 1%; */
                    }

                    .dm-study-container #form {
                        /* width: 85%; */
                        /* height: 85%; */
                        display: flex;
                        width: auto;
                        min-width: 85%;
                        height: auto;
                        min-height: 85%;
                        margin: 5%;
                        align-items: center;
                        justify-content: center;
                    }
            
                    .dm-study-container .dm-study-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        /* margin: 10%; */
                        margin: var(--card-margin);
                        border-radius: 20px;
                        background: var(--card-background);
                    }
            
                    /* Smartphones, iPhone, portrait 480x320 phones */
                    @media (max-width: 480px) {
                        .dm-study-container {
                            flex-direction: column; /* Default to column on small smartphones */
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: column;
                        }
                    }
            
                    /* Portrait e-readers and smaller tablets */
                    @media (min-width: 481px) and (max-width: 640px) {
                        .dm-study-container {
                            flex-direction: column; /* Default to column on e-readers and small tablets */
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: column;
                        }
                    }
            
                    /* Larger smartphones and portrait tablets */
                    @media (min-width: 641px) and (max-width: 768px) {
                        .dm-study-container {
                            flex-direction: column; /* Change to row on larger smartphones and portrait tablets */
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: column;
                        }
                    }
            
                    /* Landscape phones and smaller tablets */
                    @media (min-width: 769px) and (max-width: 960px) {
                        .dm-study-container {
                            flex-direction: row; /* Change to row on larger mobile devices in landscape */
                            /* flex-wrap: wrap; */
                        }
                        .dm-study-container .dm-study-wrapper {
                            flex-direction: row;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .dm-study-container .options-container label {
                            min-width: 40%;
                            width: auto;
                        }
                        .dm-study-container #animation-container{
                            height: 5vw;
                        }
                    }
            
                    /* Tablet, landscape iPad, lo-res laptops */
                    @media (min-width: 961px) and (max-width: 1280px) {
                        /* .dm-study-container {
                            flex-direction: column; 
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: column;
                        } */
                        .dm-study-container {
                            flex-direction: row; /* Change to row on larger mobile devices in landscape */
                            /* flex-wrap: wrap; */
                        }
                        .dm-study-container .dm-study-wrapper {
                            flex-direction: row;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .dm-study-container .options-container label {
                            min-width: 40%;
                            width: auto;
                        }
                        .dm-study-container #animation-container{
                            height: 5vw;
                        }
                    }
            
                    /* Big landscape tablets, laptops, and desktops */
                    @media (min-width: 1025px) {
                        /* .dm-study-container {
                            flex-direction: column;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: column;
                        } */
                        .dm-study-container {
                            flex-direction: row; /* Change to row on larger mobile devices in landscape */
                            /* flex-wrap: wrap; */
                        }
                        .dm-study-container .dm-study-wrapper {
                            flex-direction: row;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .dm-study-container .options-container label {
                            min-width: 40%;
                            width: auto;
                        }
                        .dm-study-container #animation-container{
                            height: 5vw;
                        }
                    }
            
                    /* Mobile devices in landscape orientation */
                    @media (max-width: 768px) and (orientation: landscape) {
                        .dm-study-container {
                            flex-direction: row; /* Change to row on larger mobile devices in landscape */
                            /* flex-wrap: wrap; */
                        }
                        .dm-study-container .dm-study-wrapper {
                            flex-direction: column;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .dm-study-container #answersDiv label {
                            min-width: 40%;
                            width: auto;
                        }
                        
                        .dm-study-container #animation-container{
                            height: 5vw;
                        }
                    }

                    /* Non-landscape mobile devices */
                    @media (max-height: 150px) and (orientation: landscape) {
                        .dm-study-container {
                            flex-direction: row; /* Change to row on larger mobile devices in landscape */
                            /* flex-wrap: wrap; */
                        }
                        .dm-study-container .dm-study-wrapper {
                            flex-direction: row;
                            padding: 1%;
                        }
                        .dm-study-container #answersDiv {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .dm-study-container #answersDiv label {
                            min-width: 40%;
                            width: auto;
                        }
                        
                        .dm-study-container #animation-container{
                            height: 5vw;
                        }
                    }
            
                    .dm-study-container .header-container {
                        display: flex;
                        width: 95%;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        align-self: center;
                        height: 100%;
                        padding: 1%;
                    }
            
                    .dm-study-container .study-header-card {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 80%;
                        /* background: conic-gradient(from 46deg at 13.43% 35.68%, #6344EE 0deg, #75C5FA 360deg); */
                        border-radius: 15px;
                        margin: 1px;
                        /* margin-bottom: 10px; */
                    }
            
                    .dm-study-container .card-header {
                        /* display: flex; */
                        display: none; /* Hiding this for now as I plan to show the logo in splash screen */
                        /* justify-content: space-between; */
                        justify-content: center;
                        /* align-items: center; */
                        position: relative;
                        /* padding: 15px; */
                        /* padding-top: 1%; */
                        padding: 1%;
                        height: 20%;
                    }
            
                    .dm-study-container .progress-indicator {
                        position: relative;
                        display: none;
                        margin: 15px;
                        align-self: flex-start;
                        font-size: 1.2rem;
                        letter-spacing: 0.10rem;
                        /* color: #1A1923; */
                        color: var(--text-color);
                        font-family: "Config Rounded";
                        font-style: bold;
                        font-weight: 500;
                        line-height: normal;
                        visibility: hidden;
                    }
            
                    .dm-study-container #animation-container {
                        /* width: 100px; */
                        height: 8vh;
                        position: relative;
                        /* display: flex; Hiding this for now to see if it fixes the issue on Safari */
                        justify-content: flex-end;
                        /* margin: 0 auto; */
                    }
            
                    .dm-study-container #animation-container svg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 50%; /* Adjusted width */
                        height: auto; /* Maintain aspect ratio */
                        display: none;
                    }
            
                    .dm-study-container .svg-image {
                        display: none;
                        /* Adding below to see if it fixes the issue on Safari */
                        height: 5vh;
                        max-height: 8vh;
                        /* height: 50px; Adjusted height */
                    }
            
                    .dm-study-container .question-container {
                        position: relative;
                        display: flex;
                        width: 100%;
                        height: 80%;
                        max-width: 90%;
                        overflow-wrap: break-word;
                        /* height: auto; */
                        align-self: center;
                        justify-content: center;
                        align-items: center;
                    }
            
                    .dm-study-container .question-text {
                        width: 100%;
                        padding: var(--question-text-padding);
                        font-family: "Config Rounded";
                        /* font-size: 0.9rem; */
                        font-size: var(--question-text-size);
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.2;
                        max-width: fit-content;
                        text-align: center;
                        /* color: #1A1923; */
                        color: var(--text-color);
                    }
            
                    .dm-study-container .progress-bars {
                        position: relative;
                        display: flex;
                        gap: 5px;
                        width: 95%;
                        height: 5%;
                        align-self: center;
                        align-items: center;
                        /* margin: 10px 0px 0px 0px; */
                        margin: 1% 0% 0% 0%;
                    }
            
                    .dm-study-container .progress-bar {
                        flex-grow: 1;
                        height: 4px;
                        background-color: var(--progress-bar-inactive);
                        border-radius: 4px;
                        position: relative;
                    }
            
                    .dm-study-container .progress-bar.active {
                        background-color: var(--progress-bar-active);
                    }
            
                    .dm-study-container .progress-bar.active::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: var(--progress-bar-active-before);
                    }
            
                    .dm-study-container .content-container {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                    }
            
                    .dm-study-container .options-container {
                        display: flex;
                        flex-direction: column;
                        /* flex-wrap: wrap; */
                        /* gap: 10px; */
                        gap: var(--answers-gap);
                        /* background: rgba(255, 255, 255, 0.1); */
                        border-radius: 15px;
                        padding: 5px;
                        width: 95%;
                        height: 80%;
                        justify-content: center;
                        align-items: flex-start;
                        align-self: flex-start;
                        margin: 5px;
                    }

                    .dm-study-container .options-container label {
                        width: 95%;
                        /* height: 20%; */
                        /* width: auto;
                        min-width: 40%;
                        max-width: 95%; */
                        /* padding: 5px; */
                        padding: 2%;
                        color: var(--text-color);
                        background-color: var(--button-background);
                        border-radius: 15px;
                        border: 1px solid #333;
                        font-size: var(--answer-text-size);
                        /* font-size: 0.6rem; */
                        cursor: pointer;
                        text-align: center;
                        margin: 1%;
                        align-self: center;
                    }

                    .dm-study-container .options-container input {
                        display: none;
                    }
                    .dm-study-container .options-container span {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                    }
                    
                    .dm-study-container .option-button:hover {
                        background-color: var(--button-hover);
                        /* background-color: #333; */
                    }
            
                    .completion-screen {
                        display: none;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 85%;
                        height: 85%;
                        padding: 5%;
                    }
            
                    .dm-study-container .completion-card {
                        position: relative;
                        width: 100%;
                        height: 80%;
                        /* background: conic-gradient(from 46deg at 13.43% 35.68%, #6344EE 0deg, #75C5FA 360deg); */
                        background-color: var(--card-background);
                        border-radius: 15px;
                        margin-bottom: 10px;
                        align-items: center;
                    }
            
                    .completion-card {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: var(--text-color);
                        text-align: center;
                    }
            
                    .completion-card .thank-you-note {
                        font-size: var(--question-text-size);
                        font-weight: bold;
                        margin-bottom: 1%;
                    }
            
                    .completion-card .logo-container {
                        max-width: 150px;
                        max-height: 75px;
                    }

                    .completion-card .logo-container img {
                        width: 100%;
                        height: auto;
                    }

                    .not-active-screen {
                        display: none;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 85%;
                        height: 85%;
                        padding: 5%;
                    }

                    .dm-study-container .not-active-card {
                        position: relative;
                        width: 100%;
                        height: 80%;
                        /* background: conic-gradient(from 46deg at 13.43% 35.68%, #6344EE 0deg, #75C5FA 360deg); */
                        background-color: var(--card-background);
                        border-radius: 15px;
                        margin-bottom: 10px;
                        align-items: center;
                    }
            
                    .not-active-card {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: var(--text-color);
                        text-align: center;
                    }
            
                    .not-active-card .not-active-note {
                        font-size: var(--question-text-size);
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
            
                    .not-active-card .logo-container img {
                        max-width: 200px;
                        max-height: 100px;
                    }
            
                    .logo {
                        margin-top: 20px;
                        display: flex;
                        justify-content: center;
                    }
            
                    .fade-out {
                        animation: fadeOut 0.5s;
                        display: none;
                    }
            
                    .fade-in {
                        animation: fadeIn 0.5s;
                        display: flex;
                    }
            
                    @keyframes fadeOut {
                        from { opacity: 1; }
                        to { opacity: 0; }
                    }
            
                    @keyframes fadeIn {
                        from { opacity: 0; }
                        to { opacity: 1; }
                    }
                </style>
                <script>
                    function animateLogo() {
                        const stages = document.querySelectorAll('#welcome-animation-container .svg-image');
                        
                        let currentStage = 0; // Start from 0 for array indexing

                        function showStage(stage) {
                            // Hide all stages
                            Array.from(stages).forEach((stageElement, index) => {
                                if (index === stage) {
                                    stageElement.style.display = 'flex';
                                } else {
                                    stageElement.style.display = 'none';
                                }
                            });
                        }

                        // Initialize with stage 1 visible
                        showStage(currentStage);

                        // Set interval to change stages
                        setInterval(function () {
                            currentStage = (currentStage + 1) % stages.length; // Loop back to the first stage
                            showStage(currentStage);
                        }, 750);
                    }

                    function showWelcomeScreen() {
                        document.querySelector('.welcome-screen-container').style.display = 'flex';
                        document.querySelector('.dm-study-container').style.display = 'none';
                    }

                    function startSurvey() {
                        document.querySelector('.welcome-screen-container').style.display = 'none';
                        document.querySelector('.dm-study-container').style.display = 'flex';
                    }

                    function showThankYouScreen() {
                        document.querySelector('#form').style.display = 'none';
                        const completionScreen = document.querySelector('.completion-screen');
                        completionScreen.style.display = 'flex';
                    }

                    document.addEventListener('DOMContentLoaded', function () {
                        ${themeMode === 'system' ? 'document.documentElement.setAttribute(\'data-theme\', window.matchMedia(\'(prefers-color-scheme: dark)\').matches ? \'dark\' : \'light\');' : 'document.documentElement.setAttribute(\'data-theme\', \'' + themeMode + '\');'}
                        animateLogo();
                        ${skipWelcomeScreen ? 'showWelcomeScreen();' : ''}
                        document.querySelector('.continue-button').addEventListener('click', startSurvey);
                        document.querySelectorAll('.option-button').forEach(button => {
                            button.addEventListener('click', showThankYouScreen);
                        });
                    });

                </script>
            </head>
            <body marginwidth="0" marginheight="0">
                <canvas id="dm-canvas" width="200" height="40" style="display: none;"></canvas>
            </head>
            <body marginwidth="0" marginheight="0">
                <div id="loader" class="loading-screen-container" style="display: none;">
                    <img src="https://cdn.datamoire.com/images/dm-logo-long.png" alt="Datamoire Logo">
                    <div class="custom-loader"></div>
                </div>
                <div class="welcome-screen-container" style="display: none;">
                    <div class="welcome-card">
                        <div id="welcome-animation-container">
                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-1.svg" alt="Stage 1" class="svg-image" />
                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-2.svg" alt="Stage 2" class="svg-image" />
                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-3.svg" alt="Stage 3" class="svg-image" />
                        </div>
                        <div class="welcome-note">
                            Welcome to Datamoire Survey!
                        </div>
                        <button class="continue-button">Start Survey</button>
                    </div>
                </div>
                <div class="dm-study-container" id="dm-study">
                    <form id="form" style="align-items: center;">
                        <div class="dm-study-wrapper">
                            <div class="header-container">
                                <div class="study-header-card">
                                    <div class="card-header">
                                        <span class="progress-indicator" id="info-message">1/3</span>
                                        <div id="animation-container">
                                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-1.svg" alt="Stage 1" class="svg-image" />
                                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-2.svg" alt="Stage 2" class="svg-image" />
                                            <img src="https://cdn.datamoire.com/images/logo-anim-stage-3.svg" alt="Stage 3" class="svg-image" />
                                        </div>
                                    </div>
                        
                                    <div class="question-container" id="Question">
                                        <p class="question-text">
                                            ${questionText}
                                        </p>
                                    </div>
                                </div>
                                <div class="progress-bars" id="progress-container">
                                    <div class="progress-bar active" id="progress-bar"></div>
                                    <div class="progress-bar" id="progress-bar"></div>
                                    <div class="progress-bar" id="progress-bar"></div>
                                </div>
                            </div>
                            <div class="content-container">
                                <div class="options-container" id="answersDiv">
                                    ${answersHtml}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="completion-screen" id="thank">
                        <div class="completion-card">
                            <div class="thank-you-note">
                                Thank you!
                            </div>
                            <div class="logo-container">
                                <img src="https://cdn.datamoire.com/images/dm-logo-long.png" alt="Datamoire Logo">
                            </div>
                        </div>
                    </div>
                    <!-- This container should have the same style as the thank / completion-screencontainer -->
                    <div class="not-active-screen" id="notActive" style="display: none;">
                        <div class="not-active-card">
                            <div class="not-active-note">
                                Campaign / Study is not active yet.
                            </div>
                            <div class="logo-container">
                                <img src="https://cdn.datamoire.com/images/dm-logo-long.png" alt="Datamoire Logo">
                            </div>
                        </div>
                    </div>
                </div>

            </body>
        </html>
    `)
}
export const ReactThemeToggleButton = ({
  currentTheme,
  onChange
}) => (
  <label
    className={`theme-toggle-container ${currentTheme === 'dark' ? 'IsDark' : currentTheme === 'light' ? 'IsLight' : 'IsSystem'}`}
    title={`Activate ${currentTheme === 'dark' ? 'light mode' : currentTheme === 'light' ? 'system mode' : 'dark mode'}`}
    aria-label={`Activate ${currentTheme === 'dark' ? 'light mode' : currentTheme === 'light' ? 'system mode' : 'dark mode'}`}
    onClick={onChange} // Handle click to change theme
  >
    <input
      type="checkbox"
      checked={currentTheme === 'dark'} // Check if current theme is dark
      readOnly
    />
    <div />
  </label>
);

export const allStudyDimensions = [
  { name: "1100x250", dimensionType: "STANDARD" },
  { name: "160x600", dimensionType: "STANDARD" },
  { name: "200x200", dimensionType: "STANDARD" },
  { name: "234x60", dimensionType: "STANDARD" },
  { name: "240x400", dimensionType: "STANDARD" },
  { name: "250x250", dimensionType: "STANDARD" },
  { name: "300x1050", dimensionType: "STANDARD" },
  { name: "300x250", dimensionType: "STANDARD" },
  { name: "300x600", dimensionType: "STANDARD" },
  { name: "320x100", dimensionType: "STANDARD" },
  { name: "320x480", dimensionType: "STANDARD" },
  { name: "336x280", dimensionType: "STANDARD" },
  { name: "580x400", dimensionType: "STANDARD" },
  { name: "600x1067", dimensionType: "STANDARD" },
  { name: "728x90", dimensionType: "STANDARD" },
  { name: "970x250", dimensionType: "STANDARD" },
  { name: "970x90", dimensionType: "STANDARD" },
  { name: "Ads on Facebook Reels", dimensionType: "SOCIAL" },
  { name: "Ads on Instagram Reels", dimensionType: "SOCIAL" },
  {
    name: "Audience Network Native, Banner and Interstitial",
    dimensionType: "SOCIAL",
  },
  { name: "Audience Network Rewarded Video", dimensionType: "SOCIAL" },
  { name: "Facebook Business Explore", dimensionType: "SOCIAL" },
  { name: "Facebook Feed", dimensionType: "SOCIAL" },
  { name: "Facebook In-Stream Videos", dimensionType: "SOCIAL" },
  { name: "Facebook Marketplace", dimensionType: "SOCIAL" },
  { name: "Facebook Profile Feed", dimensionType: "SOCIAL" },
  { name: "Facebook Reels", dimensionType: "SOCIAL" },
  { name: "Facebook Right Column", dimensionType: "SOCIAL" },
  { name: "Facebook Search Results", dimensionType: "SOCIAL" },
  { name: "Facebook Stories", dimensionType: "SOCIAL" },
  { name: "Facebook Video Feeds", dimensionType: "SOCIAL" },
  { name: "Instagram Explore", dimensionType: "SOCIAL" },
  { name: "Instagram Explore Home", dimensionType: "SOCIAL" },
  { name: "Instagram Feed", dimensionType: "SOCIAL" },
  { name: "Instagram Profile Feed", dimensionType: "SOCIAL" },
  { name: "Instagram Reels", dimensionType: "SOCIAL" },
  { name: "Instagram Search Results", dimensionType: "SOCIAL" },
  { name: "Instagram Stories", dimensionType: "SOCIAL" },
  { name: "Messenger Inbox", dimensionType: "SOCIAL" },
  { name: "Messenger Sponsored Messages", dimensionType: "SOCIAL" },
  { name: "Messenger Stories", dimensionType: "SOCIAL" },
  { name: "Tiktok Branded Effect", dimensionType: "SOCIAL" },
  { name: "Tiktok Branded Mission", dimensionType: "SOCIAL" },
  { name: "Tiktok Carousel Ads", dimensionType: "SOCIAL" },
  { name: "Tiktok E-commerce", dimensionType: "SOCIAL" },
  { name: "Tiktok Global App Bundle", dimensionType: "SOCIAL" },
  { name: "Tiktok Interactive Add-ons", dimensionType: "SOCIAL" },
  { name: "Tiktok Messaging Ads", dimensionType: "SOCIAL" },
  { name: "Tiktok Pangle", dimensionType: "SOCIAL" },
  { name: "Tiktok Playable Ads", dimensionType: "SOCIAL" },
  { name: "TikTok Pulse Suite", dimensionType: "SOCIAL" },
  { name: "Tiktok Search Ads", dimensionType: "SOCIAL" },
  { name: "Tiktok Showtimes", dimensionType: "SOCIAL" },
  { name: "Tiktok Spark Ads", dimensionType: "SOCIAL" },
  { name: "Tiktok Standard Feed", dimensionType: "SOCIAL" },
  { name: "Tiktok Top Feed", dimensionType: "SOCIAL" },
  { name: "Tiktok TopView", dimensionType: "SOCIAL" },
  { name: "Youtube Bumper Ads", dimensionType: "SOCIAL" },
  { name: "Youtube In-feed Video Ads", dimensionType: "SOCIAL" },
  { name: "Youtube Masthead Ads", dimensionType: "SOCIAL" },
  { name: "Youtube Non-skippable In-Stream Ads", dimensionType: "SOCIAL" },
  {
    name: "Youtube Outstream Ads and Accompanying Content",
    dimensionType: "SOCIAL",
  },
  { name: "YouTube longs Ads", dimensionType: "SOCIAL" },
  { name: "Youtube Skippable In-Stream Ads", dimensionType: "SOCIAL" },
];

const mockQuestions = [
    {
      rowId: "cm16dz80101ufz40proahx60a",
      text: "<p>Do you enjoy using our service?</p>",
      showAnswersRandomly: false,
      answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
      deactivateAutomatically: false,
      displayOrder: 1,
      isActive: true,
      answers: [
        {
          rowId: "cm16dz80p01v4z40p7qffpjre",
          text: "Yes",
          questionId: "cm16dz80101ufz40proahx60a",
          deactivateAutomatically: false,
          isActive: true,
          isPreferredAnswer: false,
          displayOrder: 1,
          additionalTrackingPixels: [],
        },
        {
          rowId: "cm16dz80p01v5z40pj7vj6r53",
          text: "No",
          questionId: "cm16dz80101ufz40proahx60a",
          deactivateAutomatically: false,
          isActive: true,
          isPreferredAnswer: false,
          displayOrder: 2,
          additionalTrackingPixels: [],
        },
      ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60b",
        text: "<p>What type of content do you prefer?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 2,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjre",
            text: "Videos",
            questionId: "cm16dz80101ufz40proahx60b",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r53",
            text: "Articles",
            questionId: "cm16dz80101ufz40proahx60b",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt5",
            text: "Podcasts",
            questionId: "cm16dz80101ufz40proahx60b",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrf",
            text: "Blogs",
            questionId: "cm16dz80101ufz40proahx60b",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60c",
        text: "<p>Which season do you like the most?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 3,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrf",
            text: "Spring",
            questionId: "cm16dz80101ufz40proahx60c",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r54",
            text: "Summer",
            questionId: "cm16dz80101ufz40proahx60c",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt7",
            text: "Autumn",
            questionId: "cm16dz80101ufz40proahx60c",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrg",
            text: "Winter",
            questionId: "cm16dz80101ufz40proahx60c",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60d",
        text: "<p>How do you prefer to travel?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 4,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrg",
            text: "By car",
            questionId: "cm16dz80101ufz40proahx60d",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r55",
            text: "By plane",
            questionId: "cm16dz80101ufz40proahx60d",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt9",
            text: "By train",
            questionId: "cm16dz80101ufz40proahx60d",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrh",
            text: "By bus",
            questionId: "cm16dz80101ufz40proahx60d",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60e",
        text: "<p>What is your favorite cuisine?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 5,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrh",
            text: "Italian",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r56",
            text: "Chinese",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt10",
            text: "Mexican",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjri",
            text: "Indian",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v7z40p7qffpjrk",
            text: "Thai",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 5,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v6z40p7qffpjrl",
            text: "French",
            questionId: "cm16dz80101ufz40proahx60e",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 6,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60f",
        text: "<p>How often do you use social media?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 6,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjri",
            text: "Daily",
            questionId: "cm16dz80101ufz40proahx60f",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r57",
            text: "Weekly",
            questionId: "cm16dz80101ufz40proahx60f",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt13",
            text: "Monthly",
            questionId: "cm16dz80101ufz40proahx60f",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60g",
        text: "<p>What is your preferred method of communication?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 7,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrk",
            text: "Email",
            questionId: "cm16dz80101ufz40proahx60g",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r58",
            text: "Phone",
            questionId: "cm16dz80101ufz40proahx60g",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt15",
            text: "Text",
            questionId: "cm16dz80101ufz40proahx60g",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrl",
            text: "Chat",
            questionId: "cm16dz80101ufz40proahx60g",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60h",
        text: "<p>What motivates you to participate in surveys?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 8,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrl",
            text: "Incentives",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r59",
            text: "Curiosity",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt17",
            text: "Feedback",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrm",
            text: "Social interaction",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v7z40p7qffpjrn",
            text: "Learning",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 5,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v6z40p7qffpjro",
            text: "Other",
            questionId: "cm16dz80101ufz40proahx60h",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 6,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60i",
        text: "<p>How do you feel about advertisements?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 9,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrm",
            text: "I enjoy them",
            questionId: "cm16dz80101ufz40proahx60i",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r60",
            text: "They annoy me",
            questionId: "cm16dz80101ufz40proahx60i",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt19",
            text: "I ignore them",
            questionId: "cm16dz80101ufz40proahx60i",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjrn",
            text: "I find them informative",
            questionId: "cm16dz80101ufz40proahx60i",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
        ],
    },
    {
        rowId: "cm16dz80101ufz40proahx60j",
        text: "<p>What is your preferred time of day for online activities?</p>",
        showAnswersRandomly: false,
        answerSelectionTypeId: "cldt7g6z60008ca9vw6vwp2p5",
        deactivateAutomatically: false,
        displayOrder: 10,
        isActive: true,
        answers: [
          {
            rowId: "cm16dz80p01v4z40p7qffpjrn",
            text: "Morning",
            questionId: "cm16dz80101ufz40proahx60j",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 1,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v5z40pj7vj6r61",
            text: "Afternoon",
            questionId: "cm16dz80101ufz40proahx60j",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 2,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v9z40pvnkh6pt21",
            text: "Evening",
            questionId: "cm16dz80101ufz40proahx60j",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 3,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v8z40p7qffpjro",
            text: "Night",
            questionId: "cm16dz80101ufz40proahx60j",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 4,
            additionalTrackingPixels: [],
          },
          {
            rowId: "cm16dz80p01v7z40p7qffpjrn",
            text: "Anytime",
            questionId: "cm16dz80101ufz40proahx60j",
            deactivateAutomatically: false,
            isActive: true,
            isPreferredAnswer: false,
            displayOrder: 5,
            additionalTrackingPixels: [],
          },
        ],
    },
  ];

export const mockStudyDetail = {
  rowId: "clwaksvbx01byxu0pkrumui5v",
  name: "Berkant Test Study Banner",
  isActive: false,
  startDate: "2024-05-17T12:30:47.620Z",
  endDate: "2024-05-22T12:30:56.620Z",
  showQuestionsRandomly: false,
  companyId: "clds1jig10003rxi7lbafnmea",
  additionalTrackingPixels: [],
  company: {
    name: "Datamoire",
    resellerCompanyId: null,
  },
  campaignId: "clwakq9rb01ayxu0psk1weu2h",
  studyStatus: "APPROVED",
  studyAdDimensions: allStudyDimensions.map((dimension) => ({
    rowId: `clwaksvcm01c3xu0p4igfees3-${dimension.name}`, // Unique rowId for each dimension
    adDimension: {
      name: dimension.name,
      rowId: dimension.rowId,
      deviceType: dimension.deviceType,
      dimensionType: dimension.dimensionType,
    },
    styling: {
      fontSize: [16, 15],
      padding: [0, 0],
      margin: [20, 30],
      fontStyle: ["Arial Black", "Arial"],
      answersLayout: "row",
      contentPositioning: "center",
      submitButtonGap: 0,
      contentDirection: "ltr",
    },
    isDeleted: false,
  })),
  type: {
    rowId: "clhy1rm0z0012rx72fs6i9iz3",
    name: "Audience Analysis",
  },
  questionType: {
    name: "Standard",
    status: "SSAS",
  },
  verifierCompany: null,
  studyQuestions: mockQuestions.map((question, index) => ({
    ...question,
    rowId: `clwakwfb501c7xu0p0sqxs1so-${index}`, // Unique rowId for each question
  })),
};

import React, { useState, useRef } from 'react';
import './App.css';
import AdLayout from './AdLayout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReactThemeToggleButton } from './helpers';

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Set initial theme based on system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  
  const isToggling = useRef(false); // Ref to track if toggle is in progress

  const handleThemeChange = () => {
    if (isToggling.current) return; // Prevent multiple calls
    isToggling.current = true; // Set toggling to true

    setIsDarkTheme(prevTheme => {
      // Ensure the state is toggled correctly
      return !prevTheme; 
    });

    // Reset toggling after a short delay
    setTimeout(() => {
      isToggling.current = false;
    }, 100); // Adjust delay as necessary
  };


  return (
    <Router>
      <div className="App" style={{width: '100%'}}>
        <div style={{display: 'flex', justifyContent:'space-around', alignContent: 'center', alignItems: 'center'}}>
          <h3>DM Portal Study Preview - Beta</h3>
          <ReactThemeToggleButton currentTheme={isDarkTheme ? 'dark' : 'light'} onChange={handleThemeChange} />
        </div>
        <Routes>
          <Route path="/:uuid" element={<AdLayout isDarkTheme={isDarkTheme} setIsDarkTheme={setIsDarkTheme} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

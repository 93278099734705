import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { Drawer, Button, ColorPicker, InputNumber, Collapse, Switch } from 'antd'; // Importing components from antd
import { useParams } from 'react-router-dom';
import BannerAd from './BannerAd';
import './AdLayout.css';
import { APIServiceRequestMethods } from './api/mixins';
import { allStudyDimensions, mockStudyDetail } from './helpers';
import debounce from 'lodash.debounce'; // Import debounce
import { ReactThemeToggleButton } from './helpers';

// Create a ThemeContext
const ThemeContext = createContext();

const AdLayout = ({ isDarkTheme, setIsDarkTheme, handleThemeModeChange }) => { // Accept props
  const { uuid } = useParams();
  const [showBannerAds, setShowBannerAds] = useState(false);
  const [dimensionList, setDimensionList] = useState([]);
  const [displayMode, setDisplayMode] = useState("both"); // "both", "mobileOnly", or "desktopOnly"
  
  const initialThemeConfig = {
    light: {
      backgroundColor: { name: "Background Color", type: 'color-picker', value: '#ffffff', valueType: 'hex' },
      textColor: { name: "Text Color", type: 'color-picker', value: '#000000', valueType: 'hex' },
      buttonBackground: { name: "Button Background", type: 'color-picker', value: '#e0e0e0', valueType: 'hex' },
      buttonHover: { name: "Button Hover", type: 'color-picker', value: '#c3cdf7', valueType: 'hex' },
      primaryGradientColor: { name: "Primary Gradient Color", type: 'color-picker', value: '#b1a4ea', valueType: 'hex' },
      secondaryGradientColor: { name: "Secondary Gradient Color", type: 'color-picker', value: '#abd6f2', valueType: 'hex' },
      cardMargin: { name: "Card Margin", type: 'input', value: 5, valueType: '%', min: 0, max: 10 },
      cardBackground: { name: "Card Background", type: 'color-picker', value: '#b19de4', valueType: 'hex' },
      progressBarActive: { name: "Progress Bar Active", type: 'color-picker', value: '#000', valueType: 'hex' },
      progressBarInactive: { name: "Progress Bar Inactive", type: 'color-picker', value: '#ccc', valueType: 'hex' },
      progressBarActiveBefore: { name: "Progress Bar Active Before", type: 'color-picker', value: '#c3cdf7', valueType: 'hex' },
      questionTextPadding: { name: "Question Text Padding", type: 'input', value: 0, valueType: 'px', min: 0, max: 50 },
      questionTextSize: { name: "Question Text Size", type: 'input', value: 0.7, valueType: 'rem', min: 0.6, max: 3 },
      answerTextSize: { name: "Answer Text Size", type: 'input', value: 0.5, valueType: 'rem', min: 0.4, max: 2.7 },
      answersGap: { name: "Answers Gap", type: 'input', value: 0, valueType: '%', min: 0, max: 20 },
    },
    dark: {
      backgroundColor: { name: "Background Color", type: 'color-picker', value: '#121212', valueType: 'hex' },
      textColor: { name: "Text Color", type: 'color-picker', value: '#fff', valueType: 'hex' },
      buttonBackground: { name: "Button Background", type: 'color-picker', value: '#1f1f1f', valueType: 'hex' },
      buttonHover: { name: "Button Hover", type: 'color-picker', value: '#a560e0', valueType: 'hex' },
      primaryGradientColor: { name: "Primary Gradient Color", type: 'color-picker', value: '#6344EE', valueType: 'hex' },
      secondaryGradientColor: { name: "Secondary Gradient Color", type: 'color-picker', value: '#75C5FA', valueType: 'hex' },
      cardMargin: { name: "Card Margin", type: 'input', value: 5, valueType: '%', min: 0, max: 10 }, 
      cardBackground: { name: "Card Background", type: 'color-picker', value: '#6344EE', valueType: 'hex' },
      progressBarActive: { name: "Progress Bar Active", type: 'color-picker', value: '#fff', valueType: 'hex' },
      progressBarInactive: { name: "Progress Bar Inactive", type: 'color-picker', value: '#444', valueType: 'hex' },
      progressBarActiveBefore: { name: "Progress Bar Active Before", type: 'color-picker', value: '#9e52fc', valueType: 'hex' },
      questionTextPadding: { name: "Question Text Padding", type: 'input', value: 0, valueType: 'px', min: 0, max: 50 },
      questionTextSize: { name: "Question Text Size", type: 'input', value: 0.7, valueType: 'rem', min: 0.6, max: 3 },
      answerTextSize: { name: "Answer Text Size", type: 'input', value: 0.5, valueType: 'rem', min: 0.4, max: 2.7 },
      answersGap: { name: "Answers Gap", type: 'input', value: 0, valueType: '%', min: 0, max: 20 },
    },
  };

  const [themeConfigs, setThemeConfigs] = useState(() => {
    const initialConfigs = {};
    allStudyDimensions.forEach(dimension => {
      initialConfigs[dimension.name] = JSON.parse(JSON.stringify(initialThemeConfig)); // Deep copy to ensure each dimension has its own config
    });
    return initialConfigs;
  });

  const [activeDimension, setActiveDimension] = useState(null); // State to track the currently active dimension for theme settings
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [useSameQuestion, setUseSameQuestion] = useState(false); // New state for using the same question
  const [randomQuestionIndex, setRandomQuestionIndex] = useState(null); // State to track the random question index
  const [studyQuestions, setStudyQuestions] = useState([]); // State to hold study questions
  // const [fetchedStudyDetail, setFetchedStudyDetail] = useState(null); // Add this line to define the state
  const [allDimensionList, setAllDimensionList] = useState([]); // Add this line to define the state
  const isToggling = useRef(false); // Ref to track if toggle is in progress
  const [skipWelcomeScreen, setSkipWelcomeScreen] = useState(false); // Add this line to define the state

  const handleThemeChange = (name, value, themeType, valueType, min, max) => {
    if (!activeDimension) {
      console.error("No active dimension set. Cannot change theme.");
      return; // Exit if no active dimension is set
    }

    if (valueType === 'input') {
      if (isNaN(value) || value < min || value > max)  {
        console.error("Value is out of range or invalid.", value);
        return; // Exit if value is invalid
      }
    }

    setThemeConfigs((prev) => {
      const currentConfig = prev[activeDimension]; // Get the current config for the active dimension

      if (!currentConfig) {
        console.error(`No configuration found for active dimension: ${activeDimension}`);
        return prev; // Exit if no current config is found
      }

      const updatedConfig = {
        ...prev,
        [activeDimension]: {
          ...currentConfig,
          [themeType]: {
            ...currentConfig[themeType],
            [name]: {
              ...currentConfig[themeType][name],
              value: value,
            },
          },
        },
      };

      // Update dimensionList here to reflect the new theme config
      setDimensionList(prevList => prevList.map(item => ({
        ...item,
        themeConfig: updatedConfig[item.dimension] || item.themeConfig // Update themeConfig with the latest
      })));

      return updatedConfig; // Return updated theme configs
    });

    // Ensure the random question index is updated correctly
    if (activeDimension && !useSameQuestion && randomQuestionIndex !== null) {
      setRandomQuestionIndex(randomQuestionIndex); // Keep the same question index
    }
  };

  // Debounced version of handleThemeChange
  const debouncedHandleThemeChange = debounce(handleThemeChange, 1000); // Set delay to 0 for immediate updates

  const filterDimensions = (content, mode) => {
    // Apply filtering based on display mode
    const filteredContent = content.filter(item => {
      if (mode === "both") return true; // Show all
      if (mode === "mobileOnly") return item.mobileOnly; // Show mobile only
      if (mode === "desktopOnly") return !item.mobileOnly; // Show desktop only
      if (mode === "socialOnly") return item.dimensionType === "SOCIAL"; // Show social only
      return true; // Default case
    });
    // Order dimensions from smaller to larger for Standard types and then the rest
    const orderedContent = filteredContent.sort((a, b) => {
      const [widthA, heightA] = a.dimensionType === "SOCIAL" ? [100, 100] : a.dimension.split("x").map(Number);
      const [widthB, heightB] = b.dimensionType === "SOCIAL" ? [100, 100] : b.dimension.split("x").map(Number);
      const areaA = widthA * heightA;
      const areaB = widthB * heightB;

      if (a.dimensionType === "SOCIAL" && b.dimensionType !== "SOCIAL") return 1; // Place SOCIAL types last
      if (a.dimensionType !== "SOCIAL" && b.dimensionType === "SOCIAL") return -1; // Place Standard types first

      return areaA - areaB; // Sort by area for Standard types
    });

    return orderedContent;
  };

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
    // Directly filter dimensions from unfiltered list
    const updatedDimensions = filterDimensions(allDimensionList, mode); // Filter immediately
    setDimensionList(updatedDimensions);
  };
  
  const openModal = (dimension) => {
    setActiveDimension(dimension);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    // Refresh all dimensions with the latest theme configs
    setDimensionList(prevList => prevList.map(item => ({
        ...item,
        themeConfig: themeConfigs[item.dimension] // Update themeConfig with the latest
    })));
  };

  const getAdFrameRatio = (width, height) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const maxWidth = screenWidth * 0.9; // Maximum width for the ad frame (90% of the screen width)
    const maxHeight = screenHeight * 0.8; // Maximum height for the ad frame (80% of the screen height)
    const widthRatio = maxWidth / width;
    const heightRatio = maxHeight / height;
    return Math.min(widthRatio, heightRatio); // Choose the smaller of the two ratios to fit the screen
  };

  const getPlacementColumn = (width, height, availableDimensions) => {
    const isDimensionInRange = (dim, dimension) => {
      const [dimWidth, dimHeight] = dim.split("x").map((val) => parseInt(val, 10));
      const [adWidth, adHeight] = dimension.split("x").map((val) => parseInt(val, 10));
      return dimWidth <= adWidth && dimHeight <= adHeight;
    };

    const placementColumn = availableDimensions.reduce((column, ad) => {
      if (isDimensionInRange(ad, `${width}x${height}`)) {
        return ad;
      }
      return column;
    }, "right"); // Default to the right column if no match found

    return placementColumn.column || "right";
  };

  const handleThemeToggle = () => {
    if (isToggling.current) return; // Prevent multiple calls
    isToggling.current = true; // Set toggling to true

    setIsDarkTheme(prev => !prev); // Toggle the theme

    // Reset toggling after a short delay
    setTimeout(() => {
        isToggling.current = false;
    }, 100); // Adjust delay as necessary
  };

  useEffect(() => {
    const fetchAndInitializeContent = async () => {
      if (!uuid) return; // Return early if no uuid

      const studyDetailData = process.env.REACT_APP_STAGE === 'prod' ? await APIServiceRequestMethods.getBrandStudyById(uuid) : null;
      // setFetchedStudyDetail(studyDetailData);
      const studyDetail = studyDetailData && studyDetailData !== 'ERROR | Get Brand Study' ? studyDetailData : mockStudyDetail;

      const { studyAdDimensions: fetchedAdDimensions, studyQuestions: fetchedQuestions } = studyDetail; // Fetch dimensions and questions
      setStudyQuestions(fetchedQuestions); // Set state for questions

      const allContent = allStudyDimensions.map(dimension => {
        const dimensionData = fetchedAdDimensions.find(dim => dim?.adDimension?.name === dimension.name);
        if (!dimensionData) return null; // Return null if dimensionData is not found

        const [width, height] = dimensionData?.adDimension?.dimensionType !== "SOCIAL" ? dimensionData?.adDimension?.name.split("x").map((val) => parseInt(val, 10)) : [`100%`, `100%`];
        const mobileOnly = dimensionData?.adDimension?.dimensionType !== "SOCIAL" ? parseInt(width, 10) <= 320 || parseInt(height, 10) <= 100 : true;
        const column = mobileOnly ? "100%" : getPlacementColumn(width, height, fetchedAdDimensions.map(dim => dim?.adDimension?.name), displayMode);

        const questionToUse = useSameQuestion && randomQuestionIndex !== null ? fetchedQuestions[randomQuestionIndex] : fetchedQuestions[Math.floor(Math.random() * fetchedQuestions.length)];
        const themeConfig = themeConfigs[dimensionData?.adDimension?.name];
        Object.keys(themeConfig).forEach(key => {
          const { value, valueType } = themeConfig[key];
          if (['%', 'px', 'rem'].includes(valueType)) {
            themeConfig[key].value = `${value}${valueType}`;
          }
        });

        return {
          dimension: dimensionData?.adDimension?.name,
          dimensionType: dimensionData?.adDimension?.dimensionType,
          column: column,
          mobileOnly: mobileOnly,
          themeConfig: themeConfig,
          question: questionToUse
        };
      }).filter(item => item !== null);
      setAllDimensionList(allContent); // Ensure this function is defined

      const filteredContent = filterDimensions(allContent, displayMode || "both"); // Apply filtering on initial load
      setDimensionList(filteredContent);
      setShowBannerAds(true);
    };

    fetchAndInitializeContent();
  }, [uuid]); // Only run on initial load

  useEffect(() => {
    // This effect will run when displayMode, themeConfigs, useSameQuestion, or randomQuestionIndex change
    setDimensionList(prevList => prevList.map(item => {
      const question = useSameQuestion 
        ? studyQuestions[randomQuestionIndex] // Use the same question for all dimensions
        : studyQuestions.length > 0 
          ? studyQuestions[Math.floor(Math.random() * studyQuestions.length)] 
          : item.question; // Ensure question is defined

      return {
        ...item,
        themeConfig: themeConfigs[item.dimension], // Update themeConfig with the latest
        question: question // Update question based on the toggle
      };
    }));
  }, [displayMode, themeConfigs, useSameQuestion, randomQuestionIndex, studyQuestions, skipWelcomeScreen]); // Include all dependencies

  return (
    <ThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      <div>
        <div id="header" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px', backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6' }}>
          <div className="toggle-buttons" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Button 
              className={displayMode === "both" ? "active" : ""} 
              onClick={() => handleDisplayModeChange("both")}
              style={{ display: 'flex', margin: 10, minWidth: 200, backgroundColor: displayMode === "both" ? '#6c757d' : '#ffffff', color: displayMode === "both" ? '#ffffff' : '#000000', border: '2px solid #6c757d', borderRadius: '5px', transition: 'background-color 0.3s' }}
            >
              Show All Dimensions
            </Button>
            <Button 
              className={displayMode === "mobileOnly" ? "active" : ""} 
              onClick={() => handleDisplayModeChange("mobileOnly")}
              style={{ display: 'flex', margin: 10, minWidth: 200, backgroundColor: displayMode === "mobileOnly" ? '#6c757d' : '#ffffff', color: displayMode === "mobileOnly" ? '#ffffff' : '#000000', border: '2px solid #6c757d', borderRadius: '5px', transition: 'background-color 0.3s' }}
            >
              Mobile Only
            </Button>
            <Button 
              className={displayMode === "desktopOnly" ? "active" : ""} 
              onClick={() => handleDisplayModeChange("desktopOnly")}
              style={{ display: 'flex', margin: 10, minWidth: 200, backgroundColor: displayMode === "desktopOnly" ? '#6c757d' : '#ffffff', color: displayMode === "desktopOnly" ? '#ffffff' : '#000000', border: '2px solid #6c757d', borderRadius: '5px', transition: 'background-color 0.3s' }}
            >
              Desktop Only
            </Button>
            <Button 
              className={displayMode === "socialOnly" ? "active" : ""} 
              onClick={() => handleDisplayModeChange("socialOnly")}
              style={{ display: 'flex', margin: 10, minWidth: 200, backgroundColor: displayMode === "socialOnly" ? '#6c757d' : '#ffffff', color: displayMode === "socialOnly" ? '#ffffff' : '#000000', border: '2px solid #6c757d', borderRadius: '5px', transition: 'background-color 0.3s' }}
            >
              Social Only
            </Button>
          </div>
          <div className='question-toggle' style={{ display: 'flex', justifyContent: 'center', margin: '20px 0', alignItems: 'center', alignContent: 'center' }}>
            <div style={{ flex: "1 1 100%", display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
              <Switch 
                checked={skipWelcomeScreen} // Add state for skip welcome screen
                onChange={(checked) => setSkipWelcomeScreen(checked)} // Add handler for the switch
                checkedChildren="Skip Welcome Screen"
                unCheckedChildren="Show Welcome Screen"
                style={{ margin: '0 5px', minWidth: 200, marginBottom: '10px' }} // Same styling as the other switch
              />
              <Switch 
                checked={useSameQuestion} 
                onChange={(checked) => {
                  setUseSameQuestion(checked);
                  if (checked && studyQuestions.length > 0) {
                    setRandomQuestionIndex(Math.floor(Math.random() * studyQuestions.length));
                  } else {
                    setRandomQuestionIndex(null);
                  }
                }} 
                checkedChildren={`Showing ${useSameQuestion ? 'Same' : 'Random'} Question(s)`}
                unCheckedChildren={`Showing ${!useSameQuestion ? 'Random' : 'Same'} Question(s)`}
                style={{ margin: '0 5px', minWidth: 200, marginBottom: '10px' }}
              />
              {!useSameQuestion && (
                <Button 
                  onClick={() => {
                    setRandomQuestionIndex(Math.floor(Math.random() * studyQuestions.length));
                  }} 
                  size="small"
                  style={{ backgroundColor: '#99F', color: '#ffffff', borderRadius: '10px', padding: '8px 15px', marginBottom: '10px', minWidth: 200, transition: 'background-color 0.3s' }}>
                  Shuffle Question(s)
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="ad-wrapper">
          {showBannerAds && dimensionList && dimensionList.map((item) => (
            <div key={item.dimension} className={`ad-column ${item.column}`}>
              <BannerAd 
                question={item.question}
                dimension={item.dimension} 
                dimensionType={item.dimensionType} 
                themeConfig={item.themeConfig}
                themeMode={isDarkTheme ? 'dark' : 'light'}
                className="banner-ad"
                style={{
                  transform: `scale(${item.dimensionType !== "SOCIAL" ? getAdFrameRatio(
                    parseInt(item.dimension.split("x")[0], 10),
                    parseInt(item.dimension.split("x")[1], 10)
                  ) : 1})`,
                }}
                inCustomizeMode={true}
                skipWelcomeScreen={skipWelcomeScreen}
              />
              <Button onClick={() => openModal(item.dimension)} style={{width: '100%', marginTop: 20}}>Open Theme Settings</Button>
            </div>
          ))}
        </div>
        <Drawer
          title={
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <span>{`${activeDimension} Theme Settings`}</span>
              <ReactThemeToggleButton 
                    currentTheme={isDarkTheme ? 'dark' : 'light'} 
                    onChange={handleThemeToggle} // Use the new handler
                />
            </div>
          }
          placement="right"
          closable={true}
          onClose={closeModal}
          open={modalIsOpen}
          mask={false} // Disable overlay
          width="100%" // Set drawer to full width
          key={activeDimension} // Add key to prevent flickering
          bodyStyle={{padding: 10}}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
              <div style={{flex: '1 1 100%', display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                <Switch 
                  checked={skipWelcomeScreen} // Add state for skip welcome screen
                  onChange={(checked) => setSkipWelcomeScreen(checked)} // Add handler for the switch
                  checkedChildren="Skip Welcome Screen"
                  unCheckedChildren="Show Welcome Screen"
                  style={{ margin: '0 5px', minWidth: 300, marginBottom: '10px' }} // Same styling as the other switch
                />
                <Switch 
                  checked={useSameQuestion} 
                  onChange={(checked) => {
                    setUseSameQuestion(checked);
                    if (checked && studyQuestions.length > 0) {
                      setRandomQuestionIndex(Math.floor(Math.random() * studyQuestions.length));
                    } else {
                      setRandomQuestionIndex(null);
                    }
                  }} 
                  checkedChildren={`Showing ${useSameQuestion ? 'Same' : 'Random'} Question(s)`}
                  unCheckedChildren={`Showing ${!useSameQuestion ? 'Random' : 'Same'} Question(s)`}
                  style={{ display: 'flex', margin: '0 5px', marginBottom: 10 , width: '30%', minWidth: 300}}
                />
                {!useSameQuestion && (
                  <Button 
                    onClick={() => {
                      setRandomQuestionIndex(Math.floor(Math.random() * studyQuestions.length));
                    }} 
                    size="small"
                    style={{ display: 'flex', backgroundColor: '#99F', color: '#ffffff', borderRadius: '10px', padding: '8px 15px', marginBottom: '10px', transition: 'background-color 0.3s', width: '30%', minWidth: 300 }}>
                    Shuffle Question(s)
                  </Button>
                )}
              </div>
              <div style={{ display: 'flex', flex: '1 1 65%', marginBottom: 5, backgroundColor: '#f2f2f2' }} id="ad-preview-container">
                {dimensionList.find(item => item.dimension === activeDimension) && (
                  <BannerAd 
                    question={dimensionList.find(item => item.dimension === activeDimension).question}
                    dimension={activeDimension} 
                    dimensionType={dimensionList.find(item => item.dimension === activeDimension).dimensionType} 
                    themeConfig={dimensionList.find(item => item.dimension === activeDimension).themeConfig}
                    className="banner-ad"
                    themeMode={isDarkTheme ? 'dark' : 'light'}
                    stretchContent={true}
                    inCustomizeMode={true}
                    skipWelcomeScreen={skipWelcomeScreen}
                  />
                )}
              </div>
              <div style={{ display: 'flex', flex: '1 1 30%', padding: 10, flexDirection: 'column' }} id="theme-settings-container">
                <Collapse defaultActiveKey={[isDarkTheme ? '1' : '2']} activeKey={isDarkTheme ? '1' : '2'} accordion={true} style={{ width: '100%' }}>
                  {isDarkTheme ?
                    <Collapse.Panel header="Dark Mode Settings" key="1">
                      {Object.keys(themeConfigs[activeDimension]?.dark || {}).map(key => {
                        const value = themeConfigs[activeDimension]?.dark[key]?.value; // Default to empty string if undefined
                        const type = themeConfigs[activeDimension]?.dark[key]?.type; // Get the type property
                        const valueType = themeConfigs[activeDimension]?.dark[key]?.valueType; // Get the valueType property
                        const min = themeConfigs[activeDimension]?.dark[key]?.min; // Get the min property
                        const max = themeConfigs[activeDimension]?.dark[key]?.max; // Get the max property
                        return (
                          <label key={key} style={{ display: 'flex', marginBottom: '10px', width: '100%', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span style={{width: '80%'}}>{`${themeConfigs[activeDimension]?.dark[key]?.name}${type === 'input' ? ` (${valueType})` : ''}`}</span>
                            {type === 'input' ? (
                              <InputNumber
                                style={{width: '20%'}}
                                value={parseFloat(value)} // Use the value directly to avoid flickering
                                min={min} // Set min range
                                max={max} // Set max range
                                onKeyUp={(e) => {
                                  const val = e.target.value;
                                  if (val !== undefined && !isNaN(val) && val >= min && val <= max) {
                                    debouncedHandleThemeChange(key, parseFloat(val), 'dark', type, min, max); // Pass the value after a delay
                                  } else {
                                    handleThemeChange(key, parseFloat(val), 'dark', type, min, max); // Update immediately if invalid
                                  }
                                }}
                              />
                            ) : type === 'color-picker' ? (
                              <ColorPicker style={{width: '20%'}}
                                colorFormat="hex"
                                value={value} // Ensure the value is set correctly
                                onChange={(color) => {
                                  handleThemeChange(key, color.toHexString(), 'dark');
                                }}
                              />
                            ) : null}
                          </label>
                        );
                      })}
                    </Collapse.Panel> :
                    <Collapse.Panel header="Light Mode Settings" key="2">
                      {Object.keys(themeConfigs[activeDimension]?.light || {}).map(key => {
                        const value = themeConfigs[activeDimension]?.light[key]?.value; // Default to empty string if undefined
                        const type = themeConfigs[activeDimension]?.light[key]?.type; // Get the type property
                        const valueType = themeConfigs[activeDimension]?.light[key]?.valueType; // Get the valueType property
                        const min = themeConfigs[activeDimension]?.light[key]?.min; // Get the min property
                        const max = themeConfigs[activeDimension]?.light[key]?.max; // Get the max property
                        return (
                          <label key={key} style={{ display: 'flex', marginBottom: '10px', width: '100%', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span style={{width: '80%'}}>{`${themeConfigs[activeDimension]?.light[key]?.name}${type === 'input' ? ` (${valueType})` : ''}`}</span>
                            {type === 'input' ? (
                              <InputNumber
                                style={{width: '20%'}}
                                value={parseFloat(value)} // Use the value directly to avoid flickering
                                min={min} // Set min range
                                max={max} // Set max range
                                onKeyUp={(e) => {
                                  const val = e.target.value;
                                  if (val !== undefined && !isNaN(val) && val >= min && val <= max) {
                                    debouncedHandleThemeChange(key, parseFloat(val), 'light', type, min, max); // Pass the value after a delay
                                  } else {
                                    handleThemeChange(key, parseFloat(val), 'light', type, min, max); // Update immediately if invalid
                                  }
                                }}
                              />
                            ) : type === 'color-picker' ? (
                              <ColorPicker style={{width: '20%'}}
                                colorFormat="hex"
                                value={value} // Ensure the value is set correctly
                                onChange={(color) => {
                                  handleThemeChange(key, color.toHexString(), 'light');
                                }}
                              />
                            ) : null}
                          </label>
                        );
                      })}
                    </Collapse.Panel>}
                </Collapse>
                <Button onClick={closeModal} style={{ flex: '1 1 100%', display: 'flex', marginTop: '20px', width: '100%' }}>Close</Button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </ThemeContext.Provider>
  );
};

// Custom hook to use theme context
export const useTheme = () => useContext(ThemeContext);

export default AdLayout;

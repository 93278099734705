import mockDBRequestService from '../service';

const getBrandStudyById = async (id) => {
    try {
        const brandStudyData = await mockDBRequestService().get(`/study/${id}`);
        return brandStudyData.data?.data ?? {};
    } catch (error) {
        console.error(error);
        return 'ERROR | Get Brand Study';
    }
};

const BrandStudyMethods = {
  getBrandStudyById
};

export default BrandStudyMethods;

import React, { useEffect, useState } from 'react';
import { createAdPreviewLayout } from './AdPreviewLayout';

const BannerAd = ({ question, dimension, dimensionType, themeConfig, stretchContent, themeMode, skipWelcomeScreen, inCustomizeMode }) => {
  const adPreviewLayout = createAdPreviewLayout(question, themeConfig, dimension, themeMode, skipWelcomeScreen, inCustomizeMode);
  const width = dimensionType !== "SOCIAL" ? dimension.split('x')[0] : "368";
  const height = dimensionType !== "SOCIAL" ? dimension.split('x')[1] : "700";
  
  const [scaleFactor, setScaleFactor] = useState(1); // Default scale factor

  useEffect(() => {
    const updateIframeHeight = () => {
      const drawerHeight = document.querySelector('.drawer')?.clientHeight || 700; // Assuming '.drawer' is the parent of ad-container
      const aspectRatio = height / width;
      const newHeight = width * aspectRatio;

      if (newHeight > drawerHeight) {
        setScaleFactor(drawerHeight / newHeight);
      } else {
        setScaleFactor(1);
      }
    };

    updateIframeHeight();
    window.addEventListener('resize', updateIframeHeight);
    
    return () => {
      window.removeEventListener('resize', updateIframeHeight);
    };
  }, [width, height]);

  return (
    <div className="ad-container" style={{ display: 'flex', width: stretchContent ? '100%' : 'auto', maxHeight: '700px', justifyContent: 'center', marginBottom: '8%' }}>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', transform: `scale(${scaleFactor})`, transformOrigin: 'top center',}}>
        <h3>ADVERTISEMENT</h3>
        <p>{dimension}</p>
        {question && themeConfig ? 
          <iframe 
            style={{borderRadius: 20, alignSelf: 'center',  width: `${width}px`, height: `${height}px`}} 
            title="Ad Frame" 
            src={adPreviewLayout} 
            srcDoc={adPreviewLayout}
          ></iframe> : 
          <div className="placeholder-image"></div>}
      </div>
    </div>
  );
};

export default BannerAd;
